<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false">
      {{ $t('auth.continueInfo') }}
    </dashboard-page-title>

     <form @submit.prevent="save">
        <b-row>
          <b-col md="8">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> {{$t('auth.companyInfo')}}</h5>
                </template>
                <b-row>
                  <b-col  cols="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.ownerName"
                        validate="required"
                        :name="$t('auth.fullName')"
                        :placeholder="$t('auth.fullName')"
                       :label="$t('auth.fullName')"
                    />
                  </b-col>
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.name"
                        :validate="'required'"
                        :name="$t('auth.companyName')"
                        :placeholder="$t('auth.companyName')"
                        :label="$t('auth.companyName')"
                    />
                  </b-col>
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.city"
                        :validate="'required'"
                        :name="$t('auth.city')"
                        :disabled="false"
                        :placeholder="$t('auth.city')"
                        :label="$t('auth.city')"
                    />
                  </b-col>
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.ownerNationalID"
                        :validate="{ required: true, numeric:true, digits:10,  regex: /^[1-2][0-9]*$/}"
                        :name="$t('auth.IdNumber')"
                        :disabled="false"
                        :placeholder="$t('auth.IdNumber')"
                        :label="$t('auth.IdNumber')"
                    />
                  </b-col>
                  <b-col  md="4">
                    <div class="d-flex  mb-3">
                      <input-form
                          class="mb-3 flex-1 flex-grow-1 phone-input"
                          v-model="companyInfo.phone"
                          :validate="'required'"
                          :name="$t('auth.phoneNumber')"
                          :disabled="false"
                          :placeholder="$t('auth.phoneNumber')"
                          :label="$t('auth.phoneNumber')"
                      />
                      <vue-country-code
                          v-model="companyInfo.countryCode"
                          @onSelect="onSelect"
                          :onlyCountries="['sa', 'eg']"
                          :dropdownOptions="{ disabledDialCode: true }"
                          :enabledCountryCode= true
                          defaultCountry="sa"
                          class="code-input"
                      />
                    </div>
                  </b-col>

                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.email"
                        :validate="'required'"
                        :name="$t('auth.email')"
                        :disabled="false"
                        :placeholder="$t('auth.email')"
                        :label="$t('auth.email')"
                        type="email"
                    />
                  </b-col>
                  <b-col  md="8">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.address"
                        :validate="'required'"
                        :name="$t('auth.address')"
                        :disabled="false"
                        :placeholder="$t('auth.address')"
                        :label="$t('auth.address')"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-image"></i> {{ $t('auth.images') }}</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-4" v-for="(doc, key) in requiredDocuments" :key="key">
                    <cropper-images
                        :label="doc.name"
                        nameOfImage="image.jpg"
                        @cropper-save="saveIdImage"
                        :progressLoading="progressIdImage"
                        :multi="false"
                        :imageUrl="companyInfo.company_docs"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
          <b-col md="3">
            <b-card class="iq-border-radius-10 mb-3 main-actions">
              <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-save"></i>
                  {{ $t('auth.publish') }}
                </h5>
              </template>
              <div v-if="loadingSubmitForm">
                <b-button variant="primary" class="w-100" disabled>
                  <spinner-loading text="يتم الحفظ"></spinner-loading>
                </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                  <b-button variant="primary"  class="w-100" type="submit" > {{ $t('auth.save') }} </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </form>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
// import settingsServices from '../services/settings'
import mainInfoMixins from '@/mixins/mainInfo'
// import imgUploadBox from '../components/imgUploadBox'
import transportationServices from '@/modules/Company/settings/services/settings'
export default {
  mixins: [mainInfoMixins],
  // components: { imgUploadBox },
  mounted () {
    core.index()
  },
  data () {
    return {
      requiredDocuments: null,
      companyInfo: {
        ownerName: '',
        name: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user.name : '',
        phone: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user.phone : '',
        email: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user.email : '',
        city: '',
        address: '',
        ownerNationalID: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user.national_id : '',
        company_docs: '',
        countryCode: ''
      },
      loadingSubmitForm: false,
      loadingGallery: 0,
      removeLoadingUi: false,
      progressIdImage: 0
    }
  },
  methods: {
    getRequiredDocuments () {
      transportationServices.getRequirementsDocuments().then(response => {
        this.requiredDocuments = response.data.data
      })
    },

    saveIdImage (file) {
      const formData = new FormData()
      formData.append('image_name', file.imageInfo.name)
      formData.append('directory', 'Companies')
      formData.append('file', file.image)
      formData.append('app_name', 'transportation')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressIdImage = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.companyInfo.company_docs.push(res.data.url)
        this.showSuccessUploadFile()
      })
    },
    onSelect ({ name, dialCode }) {
      const countryCode = {
        20: '0020',
        966: '00966'
      }
      this.companyInfo.countryCode = countryCode[dialCode]
    },

    save () {
      this.loadingSubmitForm = true

      // if (this.code === '00966' && this.companyInfo.phone.charAt(0) === '0') {
      //   this.fullNumber = this.companyInfo.phone.slice(1)
      // } else {
      //   this.fullNumber = this.companyInfo.phone
      // }

      transportationServices.uploadRequiredDocsFiles(this.companyInfo).then(res => {
        core.showSnackbar('success', res.data.message)
      }).finally(() => {
        this.loadingSubmitForm = false
      })
    }
  },
  created () {
    this.getRequiredDocuments()
  }
}
</script>
<style>
.main-actions {
  position: sticky !important;
  top: 0px;
}
.phone-input .form-control{
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.code-input{
  margin-top: 33px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
}
</style>
